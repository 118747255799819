import { useMemo } from 'react';

// utils
import { isCNAddress } from '../utils/address';

// ----------------------------------------------------------------------

export default function useImagePrefix(currentPrefix:string = ''): string {
    const OSS_BUCKET_HOST = 'https://learmonade-upload-oss-bucket.oss-cn-shanghai.aliyuncs.com/frontend-image';
    const isCNDomain = useMemo(() => isCNAddress(), []);
    const imagePrefix = isCNDomain ? OSS_BUCKET_HOST : currentPrefix;
    return imagePrefix;
}
